<template>
    <div class="calculator-container">
        <v-btn rounded outlined class="mx-2" color="primary" @click="open = !open">
            <v-icon class="data-hj-whitelist">mdi-calculator</v-icon>
        </v-btn>
        <div class="calculator" :class="[{'scientific': scientificMode}]" v-show="open">
            <v-switch v-model="scientificMode" :label="$t('modules.exams.assestment.graphicQuestions.scientificMode')" color="primary" class="mt-0 mb-2 switch" hide-details></v-switch>
            <input type="text" v-model="display" maxlength="20" class="px-5 text-right data-hj-whitelist" readonly>
            <div class="calc-buttons">

                <div class="scientific-functions" v-if="scientificMode">
                    <button class="button complex-stuff data-hj-whitelist" @click="percent">%</button>
                    <button class="button complex-stuff data-hj-whitelist" @click="factorial">x!</button>
                    <button class="button complex-stuff data-hj-whitelist" @click="exponent">x^</button>
                    <button class="button complex-stuff data-hj-whitelist" @click="plusMinus">±</button>
                    <button class="button complex-stuff data-hj-whitelist" @click="ln">ln</button>
                    <button class="button complex-stuff data-hj-whitelist" @click="exp">e</button>
                    <button class="button complex-stuff data-hj-whitelist" @click="square">x²</button>
                    <button class="button complex-stuff data-hj-whitelist" @click="log">log</button>
                    <button class="button complex-stuff data-hj-whitelist" @click="cos">cos</button>
                    <button class="button complex-stuff data-hj-whitelist" @click="squareRoot">√</button>
                    <button class="button complex-stuff data-hj-whitelist" @click="sin">sin</button>
                    <button class="button complex-stuff data-hj-whitelist" @click="tan">tan</button>
                    <button class="button complex-stuff data-hj-whitelist" @click="pi">&#x003C0;</button>
                    <button class="button complex-stuff data-hj-whitelist" @click="radians">∘</button>
                    <button class="button complex-stuff data-hj-whitelist" @click="degrees">rad</button>
                </div>

                <div class="basic-functions">
                    <button class="data-hj-whitelist button triggers" @click="clear">AC</button>
                    <button class="data-hj-whitelist button triggers" @click="backspace">CE</button>
                    <button class="data-hj-whitelist button basic-stuff" @click="basicAction('(')">(</button>
                    <button class="data-hj-whitelist button basic-stuff" @click="basicAction(')')">)</button>
                    <button class="data-hj-whitelist button numbers" @click="number('7')">7</button>
                    <button class="data-hj-whitelist button numbers" @click="number('8')">8</button>
                    <button class="data-hj-whitelist button numbers" @click="number('9')">9</button>
                    <button class="data-hj-whitelist button basic-stuff" @click="divide">÷</button>
                    <button class="data-hj-whitelist button numbers" @click="number('4')">4</button>
                    <button class="data-hj-whitelist button numbers" @click="number('5')">5</button>
                    <button class="data-hj-whitelist button numbers" @click="number('6')">6</button>
                    <button class="data-hj-whitelist button basic-stuff" @click="multiply">*</button>
                    <button class="data-hj-whitelist button numbers" @click="number('1')">1</button>
                    <button class="data-hj-whitelist button numbers" @click="number('2')">2</button>
                    <button class="data-hj-whitelist button numbers" @click="number('3')">3</button>
                    <button class="data-hj-whitelist button basic-stuff" @click="basicAction('-')">-</button>
                    <button class="data-hj-whitelist button basic-stuff" @click="basicAction('.')">.</button>
                    <button class="data-hj-whitelist button numbers" @click="number('0')">0</button>
                    <button class="data-hj-whitelist button triggers" @click="equals">=</button>
                    <button class="data-hj-whitelist button basic-stuff" @click="basicAction('+')">+</button>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Calculator',
        data() {
            return {
                open: false,
                scientificMode: false,
                display: '',
            }
        },
        methods: {
            number(number) {
                this.checkError()
                this.display += number
            },
            checkError() {
                if (this.display == 'Error') this.display = ''
            },
            basicAction(action) {
                this.checkError()
                if ((action == '.' ||
                    action == '+' ||
                    action == '-') &&
                    this.display.substr(-1) != action) this.display += action
                else if (action == '(' || action == ')') this.display += action
            },
            syntaxError() {
                try {
                    this.display = eval(this.display).toString()
                } catch (error) {
                    if (error instanceof SyntaxError || error instanceof ReferenceError || error instanceof TypeError) {
                        this.display = 'Error'
                    }
                }
            },
            equals() {
                if ((this.display).indexOf('^') > -1) {
                    const base = (this.display).slice(0, (this.display).indexOf('^'));
                    const exponent = (this.display).slice((this.display).indexOf('^') + 1);
                    this.display = eval(`Math.pow(${base},${exponent})`).toString();
                } else {
                    this.syntaxError()
                }
            },
            clear() {
                this.display = '';
            },
            backspace() {
                this.checkError()
                this.display = this.display.substring(0, this.display.length - 1);
            },
            multiply() {
                this.checkError()
                if (this.display.substr(-1) != '*') this.display += '*';
            },
            divide() {
                this.checkError()
                if (this.display.substr(-1) != '/') this.display +=  '/';
            },
            plusMinus() {
                this.checkError()
                if (this.display.charAt(0) === '-') this.display = this.display.slice(1);
                else this.display = `-${this.display}`;
            },
            factorial() {
                this.checkError()
                if (this.display === 0) {
                    this.display = '1';
                } else if (this.display < 0) {
                    this.display = 'undefined';
                } else {
                    let number = 1;
                    for (let i = this.display; i > 0; i--) {
                        number *=  i;
                    }
                    this.display = number;
                }
            },
            pi() {
                this.checkError()
                this.display = (this.display * Math.PI);
            },
            square() {
                this.checkError()
                this.display = eval(this.display * this.display);
            },
            squareRoot() {
                this.checkError()
                this.display = Math.sqrt(this.display);
            },
            percent() {
                this.checkError()
                const disp = this.display
                let match = disp.match(/\d+\.\d+$/)
                if (match === null) match = disp.match(/\d+$/)

                try {
                    if (match) this.display = disp.substring(0, match.index) + (parseFloat(match[0]) / 100)
                    else this.display = eval(this.display) / 100
                } catch (error) {
                    if (error instanceof SyntaxError || error instanceof ReferenceError || error instanceof TypeError) {
                        this.display = 'Error'
                    }
                }
            },
            sin() {
                this.checkError()
                this.display = Math.sin(this.display);
            },
            cos() {
                this.checkError()
                this.display = Math.cos(this.display);
            },
            tan() {
                this.checkError()
                this.display = Math.tan(this.display);
            },
            log() {
                this.checkError()
                this.display = Math.log10(this.display);
            },
            ln() {
                this.checkError()
                this.display = Math.log(this.display);
            },
            exponent() {
                this.checkError()
                if (this.display.substr(-1) != '^') this.display += '^';
            },
            exp() {
                this.checkError()
                this.display = Math.exp(this.display);
            },
            radians() {
                this.checkError()
                this.display = this.display * (Math.PI / 180);
            },
            degrees() {
                this.checkError()
                this.display = this.display * (180 / Math.PI);
            }
        }
    }
</script>

<style lang="sass" scoped>
.calculator-container
    position: relative

    .calculator
        display: flex
        flex-direction: column
        position: absolute
        z-index: 9999
        top: 50px
        right: 0
        width: 320px
        background-color: lightgray
        padding: 20px
        border-radius: 10px

        &.scientific
            width: 530px !important

        & .switch
            justify-content: flex-end
            margin-right: 3px

        input[type=text]
            height: 40px
            border-radius: 5px
            border: 0px
            background-color: #333333
            color: #d9d9d9
            padding: 0 5px 0 5px
            margin: 0 3px 10px

        .calc-buttons
            display: flex
            flex-wrap: wrap
            justify-content: space-between

            .basic-functions
                width: 280px
                display: flex
                flex-wrap: wrap
                justify-content: space-evenly

            .scientific-functions
                width: 210px
                display: flex
                flex-wrap: wrap
                justify-content: space-between

            .button
                margin: 3px
                width: 63px
                border: none
                height: 25px
                border-radius: 4px
                color: #000000
                cursor: pointer

                &:hover
                    // background-color: hsla(180, 100%, 40%, 0.3)
                    transition: .2s

                &:focus
                    outline: none

            .triggers
                background-color: #ffc266
                &:hover
                    background-color: #ffad33
                

            .numbers
                background-color: #efefef
                &:hover
                    background-color: #d9d9d9

            .basic-stuff
                background-color: #66BDFF
                &:hover
                    background-color: #33a7ff

            .complex-stuff
                background-color: #65cd99
                &:hover
                    background-color: #3ec180
</style>